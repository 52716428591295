var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
//
import useLocales from './useLocales';
export default function ThemeLocalization(_a) {
    var children = _a.children;
    var outerTheme = useTheme();
    var currentLang = useLocales().currentLang;
    var theme = createTheme(outerTheme, currentLang.systemValue);
    return _jsxs(ThemeProvider, __assign({ theme: theme }, { children: [" ", children, " "] }));
}
